<template>
    <div class="form-group has-label autocomplete-field" v-click-outside="handleBlur">
        <label class="form-control-label">{{title}}</label>
        <div v-if="data" class="input">
            {{data.label}} <a-button class="x-change" @click="toggleChange" title="vymeniť"><i class="fas fa-exchange-alt"/></a-button>
        </div>
        <div v-if="!data || changeTo">
            <a-input class="form-control-alternative  form-control"
                     @input="(e) => searchWaste(e,slug)"
                     v-model="datatoShow"
                     :value="keywords"
                     placeholder="Začnite písať pre vyhľadávanie"

            />
            <ul v-if="dataSource">
                <li @click="(e) => setValue(e,res.id)" :key="res.id" v-for="res in dataSource" v-html="res.value"></li>
            </ul></div>
    </div>
</template>

<script>

    import {dataService} from "../../_services";
    
    export default {
        name: "autoComplete",
        props: ["data","title","contractor","slug","values"],
        data() {
            return {
                dataSource: [],
                datatosave: '',
                changeTo: !this.data,
                datatoShow: '',
                keywords: ''
            };
        },
        methods: {
            toggleChange()
            {
                this.changeTo = !this.changeTo;
            },
            handleBlur()
            {
                this.dataSource = [];
            },
            searchWaste(event,slug) {
                const value = event.target.value

                this.keywords = value
                if (value.length > 2) {

                    dataService.axiosPost(slug, {'search' :value,'context': this.contractor}).then(results => {
                        this.dataSource = results.data.data;
                    });

                }
                if (value.length < 3)
                {this.dataSource = []}
            },
            setValue(e,value) {
                this.datatoShow = e.target.innerText;
                this.dataSource = [];
                this.changeTo = false;
                this.data.label = e.target.innerText;
                this.$emit('changed', value);
            },
        },
        mounted() {
if(this.data)
    this.data = this.data[0];

        }
    }
</script>

<style scoped>
    .x-change
    {
        float: right;
        top: -4px;}
    .input
    {display:block;border:1px solid #bad9f1;
        min-height:46px;
        padding: 10px;
        background-color: #ffffff;}
    .autocomplete-field ul,
    .autocomplete-field ul li
    {
        list-style-type: none; padding:0; margin:0;
    }
    .autocomplete-field ul li
    {display:block;padding:5px;border:1px solid #bad9f1;
        border-top:0;font-size:15px;
        background-color: #f9f9f9;
    }

    .autocomplete-field ul li em
    {font-weight:bold;}
    .autocomplete-field ul li:hover
    {   background-color: #efefef;
        cursor:pointer;}
</style>

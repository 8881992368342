<template>
 <div>
     <div class="form-group has-label">
 <a-upload-dragger name="upload" :multiple="true" :customRequest="(e) => testo(e)" :headers="head" :showUploadList="false" style="margin-bottom:20px;display:inline-block;width:100%;">
         <p class="ant-upload-drag-icon" style="padding-top:20px">
             <a-icon type="file-image" />
         </p>
         <p class="ant-upload-text">
             Klikni, alebo premiestni súbory do tohoto poľa
         </p>

     </a-upload-dragger>

     <div class="setup-panel" v-if="data">
         <div class="mb-4 text-right" >
             <a-select class="w-50 float-right" :defaultValue="global" placeholder="globálny štýl galérie" @change="(e) => handleGlobalEdit(e,id)" :options="styles"/>
         </div>
         <div class="gallery-images mt-4" :key="id" v-if="data">
             <draggable tag="div" @change="log" :list="data" v-bind="dragOptions" @start="drag=true" @end="drag=false"  class="list-group" handle=".handle">
                 <div v-for="(imageItem,index) in data"  :key="'img'+id+'_'+index" class="gallery-image-holder">
                    <span class="handle">
                         <img :src="imageItem.uri" class="w-100">
                         <div class="image-setup"><a-icon type="edit" @click="setupImage(imageItem,index,id)"/><a-icon type="delete" @click="deleteThis(index)"/></div>
                     </span>
                 </div>
             </draggable>
         </div>
     </div>

 </div>

     <a-modal title="Nastavenia obrázka v galérii" centered  v-model="ImagesetupVisible" v-if="ImagesetupVisible" @ok="setupImageOK">
         <a-form-item  label="Štýl obrázka" class="image-property">
             <a-select placeholder="štýl obrázka" class="w-100" v-model="imageToSet.style"  :options="styles"/>
         </a-form-item>
         <a-form-item class="image-property w-100" >
             <a-input  placeholder="Vlastný rozmer" class="w-100" v-model="imageToSet.style"/>
         </a-form-item>
         <a-form-item label="Názov obrázka" class="image-property">
             <a-input placeholder="Názov"  class=" "  v-model="imageToSet.title" />
         </a-form-item>
         <a-form-item   label="Alt tag obrázka" class="image-property">
             <a-input placeholder="Alt"  class=" "  v-model="imageToSet.alt" />
         </a-form-item>
     </a-modal>

 </div>
</template>

<script>
    import {getURL} from "../../api";
    import draggable from "vuedraggable";
    import {authHeader} from "../../_helpers";



    function dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type: mime});
    }

    export default {
        name: "Gallery",
        props: ['id','data','global','styles'],
        data() {
            return {
                url: getURL(),
                customsize: false,
                imageToSet:{},
                ImagesetupVisible:false,
                head: authHeader(),
            }
        },
        components: {draggable},
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    datatosave:false,
                    countitem: 0,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            clicked: function(event) {
                if(event.ctrlKey)
                    this.customsize = !this.customsize;
            },
            sendImage(formData)
            {

                const pa = this;
                this.axios.post(this.url.API_URL + '/upload/content',
                    formData,
                    {
                        headers: authHeader(true)
                    }
                ).then(function (data) {
                    pa.datatosave = data.data[0];

                    let items = [];

                    var u = {};
                    u = data.data[0];

                    if(pa.data)
                    {
                        items = pa.data;
                        items.push(u);

                    }
                    else
                    {
                        items.push(u);
                    }

                    pa.data = items;
                    pa.$emit('changed',  pa.data);
                });

            },
            hasAlpha (context, canvas) {
                var data = context.getImageData(0, 0, canvas.width, canvas.height).data,
                    hasAlphaPixels = false;
                for (var i = 3, n = data.length; i < n; i+=4) {
                    if (data[i] < 255) {
                        hasAlphaPixels = true;
                        break;
                    }
                }
                return hasAlphaPixels;
            },
            normFile(e) {

                if (Array.isArray(e)) {
                    return e;
                }
                return e && e.fileList;


            },

            testo(e)
            {


                var img = new Image;
                let formData = new FormData();
                const pa = this;
                img.onload = function() {

                        formData.append('upload', e.file);

                    pa.sendImage(formData);

                }

                img.src = URL.createObjectURL(e.file);

                return false
            },
            setupImage(image,index,slug)
            {

                this.imageToSet = image;
                this.imageToSet.index = index;
                this.imageToSet.slug = slug;
                this.ImagesetupVisible = true;
            },
            array_move(arr, old_index, new_index) {
                if (new_index >= arr.length) {
                    var k = new_index - arr.length + 1;
                    while (k--) {
                        arr.push(undefined);
                    }
                }
                arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
                return arr; // for testing
            },
            log()
            {
                this.$emit('changed',  this.data);
            },
            deleteThis(index)
            {
                let parsed = this.data;
                parsed.splice(index, 1);
                this.data = parsed;

            },
            handleGlobalEdit(e)
            {
                let parsed = this.data;
                parsed.forEach(obj => {
                    obj.style = e;
                });

                this.data = parsed;
            },
            setupImageOK()
            {
                let parsed = this.data;
                parsed[this.imageToSet.index].title = this.imageToSet.title;
                parsed[this.imageToSet.index].style = this.imageToSet.style;
                parsed[this.imageToSet.index].alt = this.imageToSet.alt;
                this.data = parsed;
                this.ImagesetupVisible = false;
            },
        }
    }
</script>

<style scoped>

    .gallery-image-holder
    {
         float:left;
         margin-right:2%;
        margin-left:0.2%;
         width:31.7%;
         outline:1px solid #e4e4e4;
         border:10px solid white;
         overflow:hidden;
         position:relative;
        margin-bottom:0.7rem;
         box-shadow: 8px 8px 10px -12px black;
    }
    .gallery-image-holder
    {
        min-height:200px;
    }
    .list-group div:nth-child(3n) {
    margin-right:0
    }
    .gallery-image-holder img.big
    {height:100%;width:auto;}

    .demo-image
    {top:0;
        position:absolute;
        width:80px;height:80px;
        border:2px solid #1d2226;
        background-color:#1d2226;
        padding:3px;
        right:-90px;
        display:none;
    }
    .demo-image img
    {max-width:100%;max-height:100%;}

    .gallery-images
    {width:100%;display:inline-block;}
    .gallery-image-holder .title
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
        max-width:200px;overflow:hidden;bottom:15px;left:5px;}
    .gallery-image-holder .alttag
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
        max-width:200px;overflow:hidden;bottom:3px;left:5px;}
    .image-setup
    {
        display:block;
        width:100%;
        bottom:-2px;
        right:-2px;
        position:absolute;
        z-index:9;
        text-align:right;
    }
    .ant-radio-group
    {width:100%;}
    .element.hideimages
    {
        padding:0;
        border-bottom:1px solid #fff;
        border-radius:0;
    } .list-group
      {display:block;}
    .image-setup i:hover
    {background-color:#d31e40;
        cursor:pointer;}
    .setup-panel
    {display:inline-block;
        width:100%;position:relative;}
    .show-example
    {position:relative;}
    .show-example img
    {margin:0 auto;}
    .show-example:hover .demo-image
    {display:block;}
    .btn-inner--icon i.fad
    {top:0}
    .image-setup i
    {
        padding:10px;
        color:#fff;
        font-weight:bold;
        background-color: #000000;
        margin:2px;
    }
    .builder-modal .form-group
    {margin-bottom:1rem}
    .builder-content .item h4
    {font-size:14px;margin:0;}
    .manager
    {width:800px}
    .top-holder
    {width:100%; padding:15px   }
    .element-image
    {width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}
</style>

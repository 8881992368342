<template>

    <div class="builder-panel">
      <a-modal title="Pridanie obsahu" width="60%" style="max-width:920px"  class="builder-content" centered v-model="visible" @ok="handleOk">
        <a-tabs  defaultActiveKey="tempcat:0" :tabPosition="mode" @prevClick="callback" @nextClick="callback">
            <a-tab-pane :tab="cat.title" :key="'tempcat:' + iin" v-for="(cat,iin) in taxonomy">
               <a-radio-group buttonStyle="solid" v-model="newComponent">
                    <template  v-for="(dat,i) in templates">
                    <div class="item" :key="i" v-if="settings.categories[i] === cat.id">
                        <a-radio-button :value="i" class="p-2" v-if="i.includes('view') && !disabled || !i.includes('view')">
                            <i class="fal fa-list-alt" style="position:absolute;z-index:99;right:5px;color:#eaeaea;" v-if="i.includes('view')"></i>
                            <div class="icon-image" v-if="settings.icons[i] && settings.icons[i]['uri'].includes('svg')">
                        <img :src="settings.icons[i]['uri']">
                          </div>
                            <h4>{{settings.titles[i]}}</h4>
                            <span class="text-muted d-table" style="opacity:.6;" v-if="settings.descriptions[i]">{{settings.descriptions[i]}}</span>
                        </a-radio-button>
                        <div v-if="settings.icons[i] && !settings.icons[i]['uri'].includes('svg')" class="hidden-image">
                            <img :src="settings.icons[i]['uri']">
                        </div>
                    </div>
                    </template>
                </a-radio-group>
            </a-tab-pane>
        </a-tabs>

        </a-modal>
        <a-modal v-if="showImportModal" v-model="showImportModal" @ok="crawlPage">
          URL produktu: <a-input v-model="crawlUrl"></a-input>
        </a-modal>
        <a-modal v-model="openCopy" style="max-width:900px" contentClassName="manager"   @ok="openCopy = false" @cancel="openCopy = false" title="Export" >
            <a-alert
                    :message="infotext"
                    type="warning"
                    closable
                    class="mb-2"
                    v-if="infotext"
                    @close="infotext = ''"
            />        <a-textarea v-model="tocopy" id="text-box" class="mb-2" style="min-height:250px;"></a-textarea>
            <a-button type="secondary" id="bo-box" class=" mb-2" size="small" @click="copyToclipboard"><i class="fad fa-clipboard pr-1"></i> Kopírovať</a-button>

        </a-modal>
        <a-modal v-model="openImport" style="max-width:900px" class="builder-modal" contentClassName="manager" @okText="Importovať" @ok="importOK" @cancel="openImport = false" title="Export" >
            <a-alert
                    :message="infotext"
                    type="warning"
                    closable
                    class="mb-2"
                    v-if="infotext"
                    @close="infotext = ''"
            />        <a-textarea v-model="tocopy" id="text-box" class="mb-2" style="min-height:250px;"></a-textarea>
               </a-modal>
        <a-modal :width="widthmodal" style="max-width:900px" contentClassName="manager" cancelText="Zrušiť" class="builder-modal"  :title="'Editácia ' + templates[editting.component].type + ' (' + editting.component + ')'" v-if="editting.component" v-model="editVisible" @ok="handleEdit(editting.id)" @cancel="closeEdit()">
            <a-tabs  default-active-key="values" tab-position="left">

                <a-tab-pane key="values" tab="Obsah" style="height:600px; overflow-x:hidden; overflow-y:scroll;">
                    <div :key="i" v-for="(dat,i) in templates[editting.component].vars">
                        <div class="form-group has-label">
                            <label class="form-control-label" v-if="settings.fields[editting.component]">
                                <span v-if="settings.fields[editting.component][dat]">{{settings.fields[editting.component][dat].name}}</span><span v-else>{{dat}}</span></label>
                            <label class="form-control-label" v-else>{{dat}}</label>
                            <div v-if="dat.toLowerCase().indexOf('file') === 0">
                           <upload :data="datatosave[dat]" :dat="dat" multiple="false" :editid="editting.id" @changed="(e) => onChildChange(e,dat)"></upload>
                        </div>
                        <div v-else-if="dat.toLowerCase().indexOf('image') === 0">
                           <image-upload :data="datatosave[dat]" :dat="dat" :editid="editting.id" @changed="(e) => onChildChange(e,dat)"></image-upload>
                        </div>
                        <div style="margin-bottom:20px" v-else-if="dat.toLowerCase().indexOf('text') === 0 || dat.toLowerCase().indexOf('description') === 0 || dat.toLowerCase().indexOf('longtext') === 0">
                             <div id="toolbar-container"></div>
                             <ckeditor :editor="editor" v-model="newcomponents[editting.id].data[dat]"  @ready="onReady" :config="editorConfig"></ckeditor>
                        </div>
                            <div v-else-if="settings.fields[editting.component][dat] && settings.fields[editting.component][dat].type === 'longtext' ">
                                <div id="toolbar-container"></div>
                                <ckeditor :editor="editor" v-model="newcomponents[editting.id].data[dat]"  @ready="onReady" :config="editorConfig"></ckeditor>

                            </div>
                        <div v-else-if="dat.toLowerCase().indexOf('formpicker') === 0">
                            <a-select class="w-100"  mode="single" v-model="datatosave[dat]" placeholder="Vyberte si z možností">
                                <a-select-option v-for="op in forms" :key="op.id">{{op.title}}</a-select-option>
                            </a-select>
                        </div>
                        <div v-else>
                            <a-input class="form-control" :placeholder="dat" @change="(e) => onChildChange(e.target.value,dat)" v-model="newcomponents[editting.id].data[dat]" />
                        </div> </div>
                    </div>
                        <div v-if="Object.keys(templates[editting.component].itemFields).length !== 0">
                           <div v-if="templates[editting.component].itemFields[0] === 'item.image' && templates[editting.component].itemFields.length === 1">

                               <gallery :styles="styles" :id="editting.id" :data="newcomponents[editting.id].data.items[templates[editting.component].itemFields[0]]" @changed="(e) => onChildChangeGallery(e,'items',templates[editting.component].itemFields[0])"></gallery>
                            </div>
                                <div v-else>
                            <multiple-items :fieldsettings="settings.fields[editting.component]" :data="newcomponents[editting.id].data.items" v-if="editting.component" :items="templates[editting.component].itemFields" :editid="editting.id" @changed="(e) => onChildChange(e,'items')"/>
                            </div>
                        </div>

                        <div v-if="templates[editting.component].type === 'view'">
                            <view-editor :url="url" :templatesCustom="sendTemplates" :data="sendfieldsData.view" :disabled="disabled" :nodespecific="sendfieldsData.view.settings.contentType === ct" :selectedCT="ct"  :vocabulary="vc" :key="'view:' + editting.id" :elements="templates[editting.component].viewFields"  :fields="settings.fields[editting.component]" :editid="editting.id" @changed="(e) => onChildChange(e,'view')"></view-editor>
                        </div>


               </a-tab-pane>
                <a-tab-pane key="settings" tab="Nastavenia">
                   <a-form-item label="Schovať blok na stránke">
                        <a-checkbox class="mb-3" v-model="sendfieldsData.hide"/>
                   </a-form-item>
                        <a-form-item label="Prenáša údaje do nasledujúceho bloku">
                        <a-checkbox class="mb-3" v-model="sendfieldsData.transferdata"/>
                        </a-form-item>
                    <span v-if="settings.fieldDefinitions[editting.component]">
                    <div class="form-group has-label" :key="aef" v-for="(setupField,aef) in settings.fieldDefinitions[editting.component].twigOptions">
                        <label class="form-control-label">{{settings.setupOptions[editting.component][aef].name}}</label>

                        <a-select class="w-100" :defaultValue="newcomponents[editting.id].data.blockSettings ? newcomponents[editting.id].data.blockSettings[aef] : ''" @change="(e) => handleSetup(e,aef)">
                            <a-select-option v-for="fiel in setupField" :key="fiel">{{settings.setupOptions[editting.component][fiel].name}}</a-select-option>
                        </a-select>


                    </div></span>
                    <div class="form-group has-label" v-if="url">
                        <label class="form-control-label" v-if="url.includes('/*')">Použiť node z parametra</label>
                        <a-checkbox class="mb-3" v-model="sendfieldsData.urlParameter" v-if="url.includes('/*')"/>
                        </div>
                    <div class="form-group has-label"  v-if="templates[editting.component].type !== 'view'">
                        <label class="form-control-label">Prednastavený štýl obrázka</label>
                    <a-select v-if="templates[editting.component].type !== 'view'" class="w-100" v-model="sendfieldsData.defaultstyle" :options="styles"/>
                    </div>
                </a-tab-pane>
            </a-tabs>
        </a-modal>
                <div class="top-holder">
                <draggable @change="updateBuilder" v-bind="dragOptions" v-model="newcomponents" group="newcomponents" @start="drag=true" @end="drag=false" handle=".handle">
                    <transition-group>

                     <template v-for="(child, index) in newcomponents">

                        <div :key="':' + index + child.component" :class="[{ 'hideimages' : !showImages},{'notshown': child.data.hide},{'transferdata': child.data.transferdata},'element']"><span class="tt">
                        <a class="handle  mr-2"><i class="fa fa-expand-arrows-alt"></i></a>
                        {{settings.titles[child.component]}}<span v-if="child.data.transferdata"> - prenáša údaje <i class="fas fa-level-down-alt"></i></span><span v-if="child.data.title || child.data.tagline"> - {{child.data.tagline | truncate(30)}} {{child.data.title | truncate(30)}}</span>
                        <a-button type="secondary" class="edit-button" @click="deleteElement(index,child.component)"  :size="size"><i class="fas fa-trash"></i><em>Zmazať</em></a-button>
                        <a-button type="secondary" class="edit-button" @click="duplicateElements(child)" :size="size"><i class="fas fa-clone"></i><em>Duplikovať</em></a-button>
                        <a-button type="secondary" v-if="!disabled" class=" edit-button" @click="exportElement(child)" :size="size"><i class="fas fa-copy"></i><em>Kopírovať</em></a-button>
                        <a-button type="secondary" class="edit-button" @click="editModal(index,child.component)"  :size="size"><i class="fas fa-pencil-alt"></i><em>Upraviť</em></a-button></span>
                        </div>
                    </template>
                </transition-group>
            </draggable>
                <div class="setup-bar pt-3">
                  <a-button type="primary" size="small" @click="showModal"><i class="fas fa-plus pr-2"></i> Pridať</a-button>
                  <a-button type="secondary" class="ml-2" size="small" @click="showImportModal = true"><i class="fas fa-plus  pr-2"></i> Nahrať</a-button>

                    <a-button type="secondary" v-if="!disabled" class="float-right ml-2" size="small" @click="exportBuilder"><i class="fas fa-file-export pr-1"></i> Export</a-button>
                    <a-button type="secondary" v-if="!disabled" class="float-right ml-2" size="small" @click="importBuilder"><i class="fas fa-file-import pr-1"></i> Import</a-button>
                </div>

    </div>
    </div>
</template>

<script>
    import {getURL} from "../../api";
    import {authHeader} from "../../_helpers";
    import ARadioGroup from "ant-design-vue/es/radio/Group";
    import Image from "./Image";
    import Upload from "./Upload";
    import MultipleItems from "./multi";
    import ViewEditor from "./View";
    import ARadioButton from "ant-design-vue/es/radio/RadioButton";
    import {dataService} from "../../_services";
    import ATabs from "ant-design-vue/es/tabs";
    import draggable from "vuedraggable";
    import gallery from './Gallery'
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    // Import translations for the German language.
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
    import ATextarea from "ant-design-vue/es/input/TextArea";
    const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8 },
    };
    const formTailLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 8, offset: 4 },
    };
    export default {
        name: "Builder",
        components: {
            ATextarea, draggable,MultipleItems, gallery, ATabs , 'view-editor': ViewEditor, 'image-upload' : Image, 'upload' : Upload, ARadioButton, ARadioGroup},
        props: ["data","values","ct","vc","url","disabled"],
        data() {
            return {
                size: 'small',
                newComponent: null,
                componentId: 0,
                sendfieldsData:{},
                sendTemplates:{},
                mode: "left",
                forms:[],
                templates: [],
                formItemLayout,
                openCopy:false,
                formTailLayout,
                widthmodal:800,
                styles:[],
                openImport: false,
                infotext:"",
                tocopy: "",
                showImages: true,
                crawlUrl:"",
                templatesCat: [],
                taxonomy: {},
                settings:{},
                editor: ClassicEditor,
                editorConfig: {
                        heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                            {
                                model: 'headingFancy',
                                view: {
                                    name: 'h3',
                                    classes: 'fancy'
                                },
                                title: 'Písané',
                                class: 'ck-heading_heading2_fancy',

                                // It needs to be converted before the standard 'heading2'.
                                converterPriority: 'high'
                            }
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo' , '|', 'HtmlEmbed', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    language: 'sk',
                },
                imagetoshow: {},
                showImportModal:false,
                datatosave: {},
                blockSettings:{},
                gurl: getURL(),
                head: authHeader(),
                editting: {'id': null,'component':null},
                newcomponents: [],
                visible: false,
                editVisible: false
            };
        },
        methods: {
            toggleThumb() {
                this.showImages = !this.showImages;
            },
            onReady()
            {
var self = this;
                setTimeout(function(){ self.widthmodal = 801; }, 150);
            },
            callback() {

            },
            showModal() {
                this.visible = true;
            },
            crawlPage()
            {
              this.showImportModal = false;
              dataService.axiosPost('crawlpage',{url:this.crawlUrl}).then(results => {


var dat = JSON.parse(results.data[0]);


dat.forEach(obj => {
  this.newcomponents.push(obj);
});
                this.updateBuilder();

            });
            },
            updateBuilder() {
                this.$emit('changed', this.newcomponents);
            },
            handleSetup(e,setu)
            {

                if (!this.datatosave.hasOwnProperty('blockSettings'))
                {
                    this.datatosave.blockSettings = {};
                }
                this.datatosave.blockSettings[setu] = e;

            },
            deleteElement(id) {
              //  this.$delete(this.newcomponents, id);
                this.newcomponents.splice(id, 1);
                this.$emit('changed', this.newcomponents);
            },
            editModal(id,component) {

                this.editting = {'id': id,'component':component};

                if(this.newcomponents[id].data.view)
                {
                      this.sendTemplates = this.newcomponents[id].data.view.settings.templates;
                      this.sendfieldsData = this.newcomponents[id].data;

                }
                else
                {this.sendfieldsData = {'defaultstyle':'','hide' : false,'transferdata' : false,'blockSettings': [],'urlParameter' : false,'view': {'settings': {'contentType' : '','templates' : {}},'items':{}}};}

                if (this.sendfieldsData.items === undefined || this.sendfieldsData.items.length == 0) {
                    {
                        this.sendfieldsData.items = {};
                    }
                }
this.datatosave = this.sendfieldsData;
                this.editVisible = true;
            },
            onChildChangeGallery(value,slug,itemslug)
            {

                if (!this.datatosave[slug].hasOwnProperty(itemslug))
                {
                    this.datatosave[slug][itemslug] = {};
                }
                this.datatosave[slug][itemslug] = value;
            },
            onChildChange(value,slug) {
                 this.datatosave[slug] = value;
                 console.log(this.datatosave[slug]);
            },
            exportElement(a)
            {
                let cloned = Object.assign({}, a);
                this.componentId = this.componentId + 1;
                cloned.id = this.componentId;
                var pa = this;
                let aarr = [];
                aarr.push(cloned);
                navigator.clipboard.writeText(JSON.stringify(aarr)).then(function() {
                    pa.infotext = "skopírované";
                }, function() {
                    pa.infotext = "neskopírované";
                });
            },
            duplicateElements(a) {
               let cloned = Object.assign({}, a);

              this.componentId = this.componentId + 1;
               cloned.id = this.componentId;

               let aarr = [];

               aarr.push(cloned);

               this.tocopy = JSON.stringify(aarr);
               this.importOK();
               this.updateBuilder();

            },
            closeEdit()
            { this.widthmodal = 800;
                this.editting = {};
            },
          isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    },
            handleEdit(id,hide = true) {
                    if(hide)
                    {  this.editVisible = false; }



                this.newcomponents[id]['data'] = this.datatosave;
                this.$emit('changed', this.newcomponents);
                this.editting = {};
            },
            exportBuilder()
            {
                this.openCopy = true;
             var text =   JSON.stringify(this.newcomponents);
             this.tocopy = text;

            },
            importOK()
            {
                this.openImport = false;

                let pa = this;
                    Object.keys(JSON.parse(pa.tocopy)).forEach(function(key) {
                        pa.newcomponents.push(JSON.parse(pa.tocopy)[key]);
                    });
                this.updateBuilder();
            },
            importBuilder()
            {

                 this.tocopy = "";

                   navigator.clipboard.readText()
                    .then(text => {
                        this.tocopy =  text;
                        this.importOK();
                    });

                 this.infotext = "";


            },
            copyToclipboard()
            {
var pa = this;
                navigator.clipboard.writeText(this.tocopy).then(function() {
                    pa.infotext = "skopírované";
            }, function() {
                    pa.infotext = "neskopírované";
            });
            },
            handleOk() {

                if(this.newComponent){
                this.componentId = this.componentId + 1;
                this.newcomponents.push({'id' : this.componentId, 'blockSettings':[], 'component' : this.newComponent,'data': {'view': {'settings':{'contentType' : '','templates' : {}}},'items':{}}});
                this.newComponent = null;
                this.visible = false;
                this.$emit('changed', this.newcomponents);

                }
            },
        },
        computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        watch: {
        },
        mounted() {
            dataService.axiosFetch('taxonomyitems',"theme_category").then(results => {
                this.taxonomy = results;
            });
            dataService.axiosFetch('imagestyles').then(results => {
                const entries = Object.entries(results)
                this.styles.push({'value': '','title':  'Original'});
                for (const [r, key] of entries) {
                    this.styles.push({'value': r,'title':  r + ' - ' + key[0]+ ' x '+ key[1]+'px'});
                }
            });

            dataService.axiosFetch('forms').then(results => {
                this.forms = results;
            });
            dataService.axiosFetch('templates').then(results => {
                this.templates = results.components;
                this.templatesCat = results.categories;

                this.settings = results.settings;

            });
           if(this.values.length > 0)
           {
           console.log(this.values);
           this.newcomponents = JSON.parse(this.values);

           console.log(this.newcomponents);
this.newcomponents.forEach(obj => {
                   if(obj.id > this.componentId)
                   this.componentId = obj.id;
               })
             }
           else
           {
            this.newcomponents = [];
            this.componentId = 0;
           }
        }
    }
</script>


<style>
.element
{display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}
.builder-content .item{
    display:block;width:97%;
    margin:0.5%;
    line-height:40px;
    max-width:550px;
}
.gallery-images
{width:100%;display:inline-block;}
.gallery-image-holder .title
{position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
    max-width:200px;overflow:hidden;bottom:15px;left:5px;}
.gallery-image-holder .alttag
{position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
    max-width:200px;overflow:hidden;bottom:3px;left:5px;}
.image-setup
{
    display:block;
    width:100%;
    bottom:-2px;
    right:-2px;
    position:absolute;
    z-index:9;
    text-align:right;
}
.ant-radio-group
{width:100%;}
.builder-content .ant-modal-content .ant-radio-group
{overflow-y:auto;
max-height:500px;}
.element.hideimages
{
padding:0;
    border-bottom:1px solid #fff;
    border-radius:0;
}
.builder-modal .form-group
{margin-bottom:1rem}
.builder-content .item h4
{font-size:14px;margin:0;}
.manager
{width:800px}
.top-holder
{width:100%; padding:15px   }
.element-image
{width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}
.ant-form-item
{margin-bottom:0}

.ant-radio-button-wrapper:hover
{background-color:#ecf6ff;}

.builder-content  .ant-radio-button-wrapper img
{height:100%;width:100%;display:block;}
.notshown span.tt
{opacity:.5}
.transferdata span.tt
{opacity:.5;
border: 1px dashed #ddd;
background-color:#ffffff}
.icon,.handle
{float:left;}
.icon
{margin-right:15px;
    border: 5px solid transparent;
    border-top: 0px;}
.builder-content .ant-radio-button-wrapper::before
{display:none!important}
.builder-content .ant-radio-button-wrapper
{padding:0;margin-bottom:5px; margin-right:.5%;
    width:100%;
    line-height:initial;
    text-align:left;
    overflow:hidden;
    height:auto;
    border:1px solid #d9d9d9;
    display: block;  }
.builder-content .ant-radio-button-wrapper-checked h4,
.builder-content .ant-radio-button-wrapper-checked .text-muted
{
    color:#fff!important;
}
.builder-content .icon-image
{float:left;
    width:50px; margin-right:5px;
height:30px;}
.builder-content .icon-image img
{  height:36px;}
.builder-content .item
{position:relative;}
.item:hover .hidden-image
{display:block;}
.hidden-image
{ display:none;
position:absolute;z-index:999;top:0;right:0;width:150px;}
.hidden-image img
{width:100%;}
.builder-modal .ant-modal-body .ant-tabs-tabpane-inactive
{display:none}
.handle:hover
{cursor:grab}
    .toggleShower
    {float:right;margin-top:-30px;}
.edit-button
{
    float:right;
    margin:0;
    margin-left:2px;
    line-height:10px;
    border-radius:0px;
    border: 0;
    color: #003366;
    background-color:transparent;
    height: 45px;
    transition: all 0s;

}
.element:hover .tt
{background-color: #f9f9f9;}
.main-button
{margin-top:20px;}
.main-button i
{position:relative;top:-5px;}
.edit-button:hover
{color: #0b80e4;
    text-shadow: 0px 0px 1px #0089ff;}
.edit-button em
{display:inherit;width:100%;clear:both; line-height:13px;font-size:11px;padding-top:5px;font-style: normal}
.edit-button >>> i
{display:inherit;margin-bottom:0;}
.ant-radio-button-wrapper img
    {width:400px}

.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.tt .icon img
{max-width:100%;}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

 .ck.ck-toolbar, .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)
{border-color: #d7dae8 !important}

@media screen and (max-width: 560px) {
    .edit-button em {
        display: none;
    }
    .edit-button
    {padding:3px}
}
@media screen and (max-width: 400px) {
    .tt .icon {
        display: none;
    }
    .element span.tt
    {font-size:12px;}
    .tt .handle
    {padding:5px;
    }}
</style>

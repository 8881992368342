<template>
     <div>
        <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="">
        <CRow>
        <CCol col="6" xl="6">
           <h6 class="w-100">Domov  >  Termín</h6>
           <h1 class="w-100" v-if="contenttype.title">{{contenttype.title}}</h1>
                    <h1 class="w-100" v-else>Nový termín</h1>
        </CCol>
        <CCol col="6" xl="6">
             <div class="text-right system-options w-100 d-block" style="">

                <router-link :to="'/taxonomy/' + contenttype.id" v-if="this.id" class="ml-2" :href="'/taxonomy/' + contenttype.id">
                    <template>
                        <a-button type="secondary" > Nastavenia</a-button>
                    </template>
                </router-link>

            </div>
        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-6 order-xl-1">
                    <a-card shadow type="secondary">
                        <template>

                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12" :key="index" v-for="(field, index) in contenttype.fields">
                                             <label class="form-control-label">{{field.title}}</label>
                                            <div class="form-item-holder" v-if="field.type === '1'"> <a-input alternative=""
                                                                                                                 :label="field.title"
                                                                                                                 :key="componentKey"
                                                                                                                 input-classes="form-control-alternative"
                                                                                                                 v-model="fieldData[field.slug]"
                                            />
                                                    <a-button  @click="setUrl"  v-if="field.slug === 'url' && fieldData['title']">Vygeneruj automatické url z názvu</a-button>
                                                <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                                <div class="sub-translations" v-if="field.translatable === '1'">
                                                    <div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                        <div  v-if="fieldData.language !== translate.short">
                                                            <a-input alternative=""
                                                                        input-classes="form-control-alternative"
                                                                        v-model="fieldData[field.slug + ':' + translate.short]"/>
                                                            <i style="position:absolute;top:10px;right:15px" title="Preložiť">{{translate.title}}</i></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mb-4" v-if="( field.type === '12' && field.multi === '0')">
                                                <a-select defaultValue="1"  style="width: 100%"  v-model="fieldData[field.slug]">
                                                    <a-select-option :value="0">Bez priradenia</a-select-option>
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>


                                            </div>
                                                 <div class="mb-4" v-if=" field.type === '12' && field.multi === '1'">

                                                <a-select v-if="fieldData[field.slug]"
                                                          mode="multiple" style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug])"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>

                                                <a-select v-else
                                                          mode="multiple"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div>
                                            <a-input alternative="" v-if="field.type === '6'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug]"
                                            />
                                            <div class="mb-4" v-if="field.type === '3'">
                                                <image-upload :data="fieldData[field.slug]" :label="field.title" :dat="field.slug"  @changed="(e) => onChildChange(e,field.slug)"></image-upload>                                             </a-form-item>
                                            </div>
                                            <a-input alternative="" v-if="field.type === '5'"
                                                        :label="field.title"
                                                        input-classes="form-control-alternative"
                                                        type="number"
                                                        v-model="fieldData[field.slug]"
                                            />
                                            <div class="mb-4" v-if="field.type === '4'" >
                                              <ckeditor :editor="editor" v-model="fieldData[field.slug]"  :config="editorConfig"></ckeditor>
                                                <i class="fal fa-globe-europe auto-url" title="Preložiť" v-if="field.translatable === '1'"></i>
                                                <div class="sub-translations" style="margin-top:10px;" v-if="field.translatable === '1'">
                                                    <div class="translation" style="position:relative;"  :key="componentKey + translate.short" v-for="translate in languages">
                                                        <div  v-if="fieldData.language !== translate.short">
                                                            <ckeditor :editor="editor" v-model="fieldData[field.slug + ':' + translate.short]"  :config="editorConfig"></ckeditor>
                                                            <i style="position:absolute;top:10px;right:15px">{{translate.title}}</i></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a-checkbox class="mb-3" v-model="fieldData[field.slug]"  v-if="field.type === '7'">
                                                {{field.title}}
                                            </a-checkbox>


                                            <div class="mb-4" v-if="field.type === '18'">
                                                <iconSelector :data="fieldData[field.slug]" class="full-width" @change="(e) => handleChangeIcon(e,field.slug)"/>
                                            </div>

                                            <div class="mb-4"  v-if="field.type === '20'">
                                               <div v-if="fieldData[field.slug]" >
                                                <compact-picker v-model="fieldData[field.slug]" :palette="['#ee4035','#ffffff', '#f37736', '#c5222a', '#7bc043', '#4FCE8B', '#0392cf', '#96ceb4', '#ff6f69','#ffcc5c', '#88d8b0','#76b4bd','#58668b','#e0ac69','#3b7dd8', '#eabaac', '#be3400', '#020d22', '#015045', '#f3a001', '#ccccf4', '#0077c5', '#313853','#fdf2b8', '#e88200', '#cb2800', '#34262b', '#2E6B2E','#3D8F3D','#4DB34D','#2F713D','#3E9550','#50B665','#31764E','#409B65','#54B97E','#327C60','#41A07C','#58BC96','#338274','#42A695','#5CBFAE','#348588','#43A9AD','#60BEC2','#36788E','#4398B3','#65ADC5','#376A94','#4485B9','#699DC7','#38599A','#4870BD','#6D8DCA','#3947A0','#4C5CC0','#717ECD','#4139A6','#5850C2','#7C76D0','#5A3AAC','#7354C5','#927AD2','#753BB2','#8E58C8','#A87FD5','#923CB9','#A95DCB','#BD83D7','#B23CBF','#C261CD','#D188D9','#C43FB6','#D066C5','#DC8DD4','#C7439E','#D26AB2','#DE91C6','#C94786','#D56FA0','#E096BA','#CC4C6F','#D7748F','#E29BAF','#CE5059','#D9787F','#E5A0A5','#D16655','#DC8A7D','#E7AEA5','#D3835A','#DEA282','#E9C0AA','#D6A05E','#E0B887','#EAD0AF','#D8BC63','#E2CD8C','#ECDFB5','#DAD668','#E4E191','#EEECBA','#C9DC6D','#D8E696','#E7F0BF','#B5DE72','#CBE89B','#E0F1C4','#A2E077','#BFEAA0','#DBF3CA','#91E27C','#B4EBA6','#D7F5CF','#81E481','#ABEDAB','#D5F6D5']" ></compact-picker>
                                            </div>
                                        </div>

                                            <div class="mb-4" v-if="field.type === '2' && field.multi != '1'">
                                                <a-select defaultValue="1"  v-model="fieldData[field.slug]" :options="options[field.id]"/>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '2' && field.multi == '1'">
                                                <a-select mode="multiple" style="width: 100%" :defaultValue="getData(fieldData[field.slug])"
                                                          @change="(e) => onChildChange(e,field.slug)" :options="options[field.id]"/>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '15'">
                                                <multi v-if="fieldData[field.slug]" :data="{'option':fieldData[field.slug]}" multiplier="false" :items="['option']" :editid="index" @changed="(e) => onChildChangeMultiP(e,field.slug)"/>
                                                <multi v-else  :items="['option']"  :editid="index" :slug="field.slug"  multiplier="false" @changed="(e) => onChildChangeMultiP(e[field.slug],field.slug)"/>
                                            </div>
                                            <div class="mb-4" v-if="field.type === '8' && field.multi != '1'">
                                                <a-select
                                                        style="width: 100%"
                                                        @change="(e) => onChildChange(e,field.slug)"
                                                        :defaultValue = "fieldData[field.slug]"
                                                        placeholder="Vyberte si zo zoznamu"
                                                >
                                                    <a-select-option v-for="(op, indd) in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select> </div>

                                            <div class="mb-4" v-if="( field.type === '12' && field.multi === '1') || (field.type === '8' && field.multi === '1')">


                                                <a-select v-if="fieldData[field.slug]" :key="field.id"
                                                          mode="multiple" style="width: 100%" :defaultValue="getData(fieldData[field.slug])"
                                                          @change="(e) => onChildChange(e,field.slug)" placeholder="Vyberte si z možností">
                                                    <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                                </a-select>
                                                <a-select v-else
                                                          mode="multiple"
                                                          style="width: 100%"
                                                          @change="(e) => onChildChange(e,field.slug)"
                                                          placeholder="Please select"
                                                >
                                                    <a-select-option v-for="(op, indd) in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                    >
                                                </a-select>

                                            </div>
                                            <div class="mb-4" v-if="field.type === '10'">
                                                <builder :data="field.slug" @clicked="(e) => onChildChange(e,field.slug)"></builder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-right">
                                    <div class="col-lg-12">
                                        <hr>
                                        <div class="mb-4">
<div class="text-right">
                                                <a class="btn  text-default btn-sm btn-icon btn-neutral" @click="cloneNode"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-copy"></i></span>Klonovať</a>
                                                <router-link  v-if="fieldData['language'] === 'sk'" :to="'/translate/' + item + '/' + id" class="btn btn-sm text-default  btn-icon btn-neutral" :href="'/translate/' + item + '/' + id">
                                                    <span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-language pr-1"></i></span>Preložiť</router-link>
                                                <a-popconfirm placement="topRight"
                                                              @confirm="confirm(item)"
                                                              @cancel="cancel"
                                                              okText="Áno"
                                                              cancelText="Nie">
                                                    <template slot="title">
                                                        <h5 color="danger" >Prajete si tento obsah vymazať?</h5>
                                                        Položka bude trvalo odstránená z vašej stránky.
                                                    </template>
                                                    <a class="btn  text-default  btn-icon btn-neutral"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-trash"></i></span>Zmazať</a>
                                                </a-popconfirm>
                                                <button class="btn btn-primary" @click="handleSubmit"><span class="btn-inner--icon" style="margin-right:5px"><i class="fas fa-save pr-1"></i></span>Uložiť</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                        </template>
                    </a-card>

                </div>  </div>
        </div>

        </CCardBody>
        </CCard>
        </CCol>
        </CRow>

        </div>
</template>
<script>
    import {API_URL} from "../api";

    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import Image from "./content/Image";
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import ADatePicker from "ant-design-vue/es/date-picker";
    import {Cropper} from 'vue-advanced-cropper'
    import Builder from "./content/Builder";
    import autoComplete from "./content/autoComplete"
    import Multi from "./content/multi";
    import moment from 'moment'
    import {Compact} from 'vue-color'
    import 'moment/locale/sk';
    import iconSelector from "./content/iconSelector";
    import Upload from "./content/Upload";

    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    // Import translations for the German language.
    import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
    import UploadAdapter from './UploadAdapter';

    var locale = {
        "lang": {
            "placeholder": "Vybrať dátum",
            "rangePlaceholder": ["Začiatok", "Koniec"],
            "today": "Dnes",
            "now": "Teraz",
            "backToToday": "Späť na dnes",
            "ok": "Ok",
            "clear": "Vymazať",
            "month": "Mesiac",
            "year": "Rok",
            "timeSelect": "Vybrať čas",
            "dateSelect": "Vybrať dátum",
            "monthSelect": "Vybrať mesiac",
            "yearSelect": "Vybrať rok",
            "decadeSelect": "Vybrať dekádu",
            "yearFormat": "YYYY",
            "dateFormat": "M/D/YYYY",
            "dayFormat": "D",
            "dateTimeFormat": "M/D/YYYY HH:mm:ss",
            "monthFormat": "MMMM",
            "monthBeforeYear": true,
            "previousMonth": "Predchádzajúci mesiac (PageUp)",
            "nextMonth": "Nasledujúci mesiac (PageDown)",
            "previousYear": "Minulý rok (Control + left)",
            "nextYear": "Nasledujúci rok (Control + right)",
            "previousDecade": "Minulá dekáda",
            "nextDecade": "Nasledujúca dekáda",
            "previousCentury": "Minulé storočie",
            "nextCentury": "Nasledujúce storoťie"
        },
        "timePickerLocale": {
            "placeholder": "Vybrať čas"
        },
        "dateFormat": "DD.MM.YYYY",
        "dateTimeFormat": "DD.MM.YYYY HH:mm:ss",
        "weekFormat": "YYYY-wo",
        "monthFormat": "YYYY-MM"
    }


    Vue.use(FormItem);
    Vue.use(Cropper);

    let id = 0;
    export default {
        name: 'addtaxonomy',
        components: {Multi, iconSelector, Builder,ADatePicker, AFormItem,'image-upload' : Image,autoComplete, 'compact-picker': Compact,
            Upload},
        props: ['id','item'],

        data() {
            return {
                fieldData: {},
                colorsto: JSON.parse(localStorage.colors),
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                ],
                contexts: {},
                options: [],
                componentKey:0,
                translations:[],
                editor: ClassicEditor,
                editorConfig: {
                    heading: {
                        options: [
                            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                            { model: 'heading3', view: 'h3', title: 'Písané', class: 'ck-heading_heading3' },
                        ]
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo', '|', 'HtmlEmbed', ],
                    table: {
                        toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                    },
                    extraPlugins: [this.uploader],
                    language: 'sk',
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                contenttypeId: {
                    uid: this.id
                },
                contenttype: {
                    title: '',
                    type: '',
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },
       methods: {
           handleChangeIcon(value,slug)
           {
             this.fieldData[slug] = value;
           },
           confirm(e) {
               dataService.axiosDelete('taxonomyitems', e).then(results => {
                   if (results) {
                       this.$router.push('/taxonomy-items/' + this.id)
                   }
               });
           },
           cancel() {
           },
           cloneNode()
           {
               dataService.axiosPost('translate/' + this.item + '/' + this.id, { 'lang' : this.fieldData.language}).then(results => {
               //    if(results.data)
                //   {this.$router.push('/edit-taxonomy-item/' + '/' + this.item + '/' +  results.data[0])}
               });
           },
            onChildChange (value,slug) {
                this.fieldData[slug] = JSON.stringify(value); // someValue
            },
            uploader(editor)
            {
                editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
                    return new UploadAdapter( loader );
                };
            },
           getData(t) {

            let th = "";
            if(t)
               th = JSON.parse(t);
               else
               th = [];
               return th;
           },
           onChildTaxChange (value,slug)
            {   this.fieldData[slug] = value;

            },
            formatDate(dt) {
                return moment(moment.unix(dt),'YYYY/MM/DD')
            },
            bool(v) {
                return v === "true" || v === "1";
            },
            onChildChangeMulti(value,slug)
            {
                for(var i in value){
                    this.fieldData[slug] = JSON.stringify(Object.values(value[i]));
                }

            },
                forceRerender() {
                this.componentKey += 1;
            },
            slugify(string) {
                const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìľłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
                const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiillmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
                const p = new RegExp(a.split('').join('|'), 'g')

                return string.toString().toLowerCase()
                    .replace(/\s+/g, '-') // Replace spaces with -
                    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
                    .replace(/&/g, '-and-') // Replace & with 'and'
                    // eslint-disable-next-line no-useless-escape
                    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                    // eslint-disable-next-line no-useless-escape
                    .replace(/\-\-+/g, '-') // Replace multiple - with single -
                    .replace(/^-+/, '') // Trim - from start of text
                    .replace(/-+$/, '') // Trim - from end of text
            },
            setUrl()
            {
                this.fieldData['url'] = this.slugify(this.fieldData['title']);
                this.forceRerender();
            },
           onChildChangeMultiP(value,slug)
           {
               this.fieldData[slug] = JSON.stringify(value);
           },
            handleSubmit() {
                if(this.item)
                {
                dataService.axiosPost("edittaxonomy/"+this.item, this.fieldData).then(results => {
                    if(results.data) { this.$router.push('/edit-taxonomy-item/'+this.item+'/'+this.id) }
                });
                }
                else {
                dataService.axiosPost(this.$options.name+"/"+this.id, this.fieldData).then(results => {
                    if(results.data) { this.$router.push('/taxonomy-items/'+this.id)}
                });
                }
            },
        },
        mounted() {
            if (this.id) {
                dataService.axiosFetch("taxonomy", this.id).then(results => {
                    this.contenttype = results;
                    this.options = results.options
                    this.ct = results.ct
                    this.contexts = results.context
                });
            }
            if (this.item) {
                dataService.axiosFetch("taxonomytermdata/"+this.id, this.item).then(resulta => {
                    this.fieldData = resulta[0];
                  if(resulta[0].options)  this.options = resulta[0].options
                });
            }


            dataService.axiosFetch('languages').then(results => {
                this.languages = results;
                results.forEach(obj => {
                    this.translations[obj.short] = {};
                });

            });


        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }

    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .page-title
    {min-height:30px}
    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .date-delete
    {float:left;margin-right:15px;margin-top:-3px;}
    .date-row{
        display: inline-block;
        width: 100%;
        border-bottom: 1px solid #e9ecfa;
        margin: 0px;
        padding: 8px;
        margin-bottom:10px;
        margin-top:-10px;
    }

    >>> .editr--content
    {background-color:#fff;}

    .gallery-image-holder
    {float:left;margin:1%;width:23%; height:200px;outline:1px solid #e4e4e4;border:10px solid white;overflow:hidden;position:relative;box-shadow: 8px 8px 10px -12px black;}
    .gallery-image-holder img.big
    {height:100%;width:auto;}

    .demo-image
    {top:0;
        position:absolute;
        width:80px;height:80px;
        border:2px solid #1d2226;
        background-color:#1d2226;
        padding:3px;
        right:-90px;
        display:none;
    }
    .sub-translations
    {margin-top:-20px;}
    #app .sub-translations >>> .ant-input, #app .sub-translations >>> .ant-select-selection--single, #app .sub-translations >>> input.form-control, #app .sub-translations >>> .ant-input-number
    {border-color: #dcedff;}
    .sub-translations i
    {color: #aecbdf
    }
    .demo-image img
    {max-width:100%;max-height:100%;}
    .ql-editor >>> strong, .ql-editor >>> b
    {font-weight:bold}
    .gallery-images
    {width:100%;display:inline-block;}
    .gallery-image-holder .title
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
        max-width:200px;overflow:hidden;bottom:15px;left:5px;}
    .gallery-image-holder .alttag
    {position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
        max-width:200px;overflow:hidden;bottom:3px;left:5px;}
    .image-setup
    {
        display:block;
        width:100%;
        bottom:-2px;
        right:-2px;
        position:absolute;
        z-index:9;
        text-align:right;
    }
    .list-group
    {display:block;}
    .image-setup i:hover
    {background-color:#d31e40;
        cursor:pointer;}
    .setup-panel
    {display:inline-block;
        width:100%;position:relative;}
    .show-example
    {position:relative;}
    .show-example img
    {margin:0 auto;}
    .show-example:hover .demo-image
    {display:block;}
    .btn-inner--icon i.fad
    {top:0}
    .image-setup i
    {
        padding:10px;
        color:#fff;
        font-weight:bold;
        background-color: #000000;
        margin:2px;
    }
    .form-item-holder
    {position:relative;}
    .form-control-label,.ant-select
    {width:100%;
        display:inline-block;}
    .auto-url:hover
    {cursor:pointer;
        color: #0b80e4;
        text-shadow: 0px 0px 1px #0089ff;}
    .auto-url
    {position:absolute;
        top:47px;
        right:18px;}
    .ck.ck-balloon-panel
    {z-index:999999!important;}
    .del-but{position:absolute;right:20px;top:-16px;}
    >>> .ck.ck-toolbar, >>> .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)
    {border-color: #d7dae8 !important}
</style>
